<template>
  <footer class="footer d-flex flex-column justify-content-center">
    <!-- Fake footer border -->
    <div class="gray-border">
      <div></div>
    </div>
    <div
      class="links d-flex flex-column align-items-center flex-md-row-reverse justify-content-center align-self-md-center">
      <!-- Footer links -->
      <div class="footerLinks d-flex">
        <nuxt-link to="/privacy" class="privacy">Privacy </nuxt-link>
        <nuxt-link to="/terms">Terms of use</nuxt-link>
        <nuxt-link to="/legal">Legal</nuxt-link>
      </div>
    </div>
    <div class="legalLine d-flex align-items-center justify-content-center align-self-md-center">
      <p>
        &copy; {{ getCurrentYear() }} Outfit7 Neo Limited. All rights reserved.
      </p>
    </div>
  </footer>
</template>

<script setup lang="ts">
function getCurrentYear() {
  return new Date().getFullYear();
}
</script>

<style scoped lang="scss">
@import '~/assets/design';

.footer {
  // position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  padding-bottom: 100px;
  background-color: #151515;

  .gray-border {
    width: 100%;
    max-width: 1160px;
    height: 1px;
    margin: 0 auto;
    background: #333;
  }

  .links {
    @media screen and (min-width: 768px) {
      width: 90%;
      max-width: 1600px;
      position: relative;
      display: block;
      margin: 0 auto;
    }

    a {
      margin: 0 20px;
      font-size: 18px;
      line-height: 25px;
      color: #fff;

      &:hover {
        color: $color-stroke-red;
      }
    }

    .footerLinks {
      margin: 24px 0;
      font-family: 'O7TF Display';
      font-weight: 600;
      display: flex;
      justify-content: center;
    }
  }

  .legalLine {
    margin: auto;

    @media screen and (min-width: 768px) {
      width: 90%;
      max-width: 1600px;
    }

    p {
      margin: 0 15px 8px 15px;
      font-size: 10px;
      font-weight: 500;
      line-height: 12px;
      color: #333;
      text-align: center;
      // text-align: center;
    }
  }
}

// global exepction from module for footer padding, based on floater presence
// :global(#app:has(.floater)) .footer {
// 	padding-bottom: 220px;
// }
</style>
