<template>
  <nav class="nav-bar">
    <div class="left-side">
      <!-- Circle -->
      <button class="circle-menu" @click.stop="toggleAppsMenu">
        <div class="circle" :class="circleClass"></div>
      </button>

      <!-- Logo -->
      <nuxt-link to="/" class="logo-link">
        <img class="logo-img" src="~/assets/images/logo-2.svg" alt="o7neo-logo" />
      </nuxt-link>

      <!-- Games Menu -->
      <transition name="fade">
        <div v-if="appsMenuIsOpen" class="apps-menu">
          <div class="d-flex flex-column">
            <div class="text-container d-flex flex-row justify-content-between">
              <p>All Games</p>
              <button @click.stop="hideAppsMenu">X</button>
            </div>

            <a target="_blank" href="https://mythic-legends.com" style="margin-bottom: 30px"><img
                src="~/assets/images/menu-item-ml.png" alt="" /></a>
          </div>
        </div>
      </transition>
    </div>

    <div class="right-side">
      <!-- Hamburger -->
      <button class="hamburger" @click.stop="toggleMobileMenu">
        <img src="~/assets/images/menu-icon.png" />
      </button>

      <!-- Mobile menu -->
      <transition name="fade">
        <ul v-if="mobileMenuIsOpen" class="menu-items mobile">
          <li v-for="(link, i) in links" :key="i" class="menu-item">
            <nuxt-link :to="link.path">{{ link.text }}</nuxt-link>
          </li>
        </ul>
      </transition>

      <!-- Desktop menu -->
      <ul class="menu-items desktop">
        <li v-for="(link, i) in links" :key="i" class="menu-item">
          <nuxt-link :to="link.path">{{ link.text }}</nuxt-link>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
// Refs/reactives
const appsMenuIsOpen = ref(false);
const mobileMenuIsOpen = ref(false);
const links = ref<TextLinkInterface[]>([
  {
    text: 'About',
    path: '/#about'
  },
  {
    text: 'Contact',
    path: '/#contact'
  },
  {
    text: 'Support',
    path: '/support'
  }
]);

// Computed values
const circleClass = computed(() => {
  return { active: appsMenuIsOpen.value };
});

// Functions
function toggleAppsMenu() {
  mobileMenuIsOpen.value = false;
  appsMenuIsOpen.value = !appsMenuIsOpen.value;
}

function toggleMobileMenu() {
  appsMenuIsOpen.value = false;
  mobileMenuIsOpen.value = !mobileMenuIsOpen.value;
}

function hideAppsMenu() {
  appsMenuIsOpen.value = false;
}
</script>

<style scoped lang="scss">
@import '~/assets/design';

nav.nav-bar {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  background-color: #151515;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);

  button {
    padding: 0;
    color: #fff;
    cursor: pointer;
    background: #151515;
    border: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);

    &:focus {
      outline: none;
    }
  }

  .left-side {
    display: flex;
    align-items: center;

    button.circle-menu {
      padding: 26px;

      .circle {
        position: relative;
        width: 28px;
        height: 28px;
        background-color: #fff;
        border-radius: 50%;

        &::after {
          position: absolute;
          top: 50%;
          right: -6px;
          width: 0;
          height: 0;
          content: '';
          border-top: 6px solid #808080;
          border-right: 7px solid #151515;
          border-left: 7px solid #151515;
          transition: border-top-color 0.15s ease-out;
          transform: translate(100%, -50%);
        }

        &.active::after {
          border-top-color: #fff;
        }
      }

      &:hover {
        .circle::after {
          border-top-color: #fff;
        }
      }
    }

    .apps-menu {
      position: absolute;
      top: 80px;
      z-index: -5;
      width: 100%;
      max-width: 450px;
      padding: 30px;
      background-color: #151515;
      transition: background-color 0;

      .text-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 0;
        margin-bottom: 30px;

        p,
        div {
          margin: 0;
          font-size: 22px;
          line-height: 27px;
          color: #808080;
        }

        div {
          color: #fff;
          cursor: pointer;
        }
      }

      img {
        width: 100%;
      }
    }

    .logo-link {
      display: none;

      @media screen and (min-width: 360px) {
        display: block;
      }
      .logo-img {
        width: 200px;
      }
    }
  }

  .right-side {
    padding-right: 30px;

    button.hamburger {
      border-bottom: none;

      @media screen and (min-width: 768px) {
        display: none;
      }
    }

    .menu-items {
      display: flex;
      margin: 0;

      &.desktop {
        display: none;

        @media screen and (min-width: 768px) {
          display: flex;
        }

        .submenu-items {
          position: absolute;
          right: 0;
          bottom: 0;
          padding: 60px 30px 30px 30px;
          background: #151515;
          transform: translate(30px, 100%);

          .submenu-item {
            white-space: nowrap;
            list-style: none;

            &:not(:last-of-type) {
              margin-bottom: 24px;
            }
          }
        }
      }

      &.mobile {
        @media screen and (min-width: 768px) {
          display: none;
        }

        position: absolute;
        right: 0;
        bottom: 1px;
        left: 0;
        z-index: -1;
        flex-direction: column;
        padding: 30px;
        background: #151515;
        transform: translateY(100%);

        .menu-item {
          &:not(:last-of-type) {
            margin-bottom: 24px;
          }

          .submenu-item {
            margin-top: 24px;
            list-style: none;
          }
        }
      }

      .menu-item {
        list-style: none;

        &:not(:last-of-type) {
          margin-right: 40px;
        }

        a,
        button {
          font-size: 20px;
          line-height: 27px;
          color: #fff;
          text-decoration: none;
          text-transform: capitalize;
          transition: color 0.15s ease-out;

          &:hover {
            color: #ff0050;
          }
        }

        .dropdown-container {
          position: relative;

          button.dropdown-button {
            position: relative;

            &::after {
              position: relative;
              top: 50%;
              right: 0;
              display: inline-block;
              width: 0;
              height: 0;
              margin-left: 5px;
              content: '';
              border-top: 6px solid #fff;
              border-right: 7px solid #151515;
              border-left: 7px solid #151515;
              transition: border-top-color 0.15s ease-out;
              transform: translateY(-50%);
            }

            &:hover::after {
              border-top-color: #ff0050;
            }

            &.active {
              color: #ff0050;

              &::after {
                border-top-color: #ff0050;
              }
            }
          }
        }
      }
    }
  }
}

// Transitions
.fade-enter,
.fade-leave-to {
  opacity: 0 !important;
}

.fade-leave-active,
.fade-enter-active {
  transition: opacity 0.15s ease-out !important;
}

// nav bar styles
.navbarMobile {
  background: #151515;
  padding: 20px 0;
  position: fixed;
  display: block;
  width: 100%;
  left: 0;
  right: 0;
  z-index: 1001;
  height: 100px;
  top: 55px;

  @media screen and (min-width: 768px) {
    display: none;
  }

  ul {
    // padding: 15px 0 10px;
    margin: 0;
    list-style-type: none;

    >li {
      display: inline-block;
      padding: 5px;
      line-height: 8px;
      margin: 10px 0;

      @media screen and (min-width: 768px) {
        padding: 0;
        // margin: 15px 0;
      }

      >a {
        padding-left: 10px;
        padding-right: 15px;
        font-size: 1.4rem;
        font-weight: 300;
        letter-spacing: 1.9px;
        text-transform: capitalize;

        &:hover {
          color: $color-stroke-red;
          text-decoration: none;
        }

        &:active {
          font-weight: 400;
        }

        @media screen and (min-width: 768px) {
          padding-left: 0;
        }
      }
    }
  }
}</style>
